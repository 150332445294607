.main-sider {
  border-right: 2px solid #DADFEA;
  width: 260px;
  min-height: 100vh;

  ul {
    border-top: 2px solid #F5F7F9;
    padding-top: 17px;

    .ant-menu-item {
      margin: auto;
      height: 48px;
      width: 226px;

      &:hover {
        height: 48px;
        width: 226px;
        border-radius: 6px;
      }
    }

    .ant-menu-item-selected {
      height: 48px;
      width: 226px;
      border-radius: 6px;
      background-color: #20709B;
      color: white;
    }

  }
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  font-family: InterExtraBold, sans-serif;
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
  font-size: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  img{
    cursor: pointer;
  }
}

.site-layout .site-layout-background {
  background: #f3f5f9;
}

.site-layout .site-layout-header-background {
  background: #FFFFFF;

  .site-header {
    justify-content: space-between;
    align-items: center;
  }
}

.page-heading {
  margin: 0 0 0 25px;
  color: #323E59;
  font-family: InterBold, sans-serif;
  font-size: 20px;
}

.right-part {
  margin-right: 25px;
  cursor: pointer;

  .username {
    display: flex;

    .profile {
      margin-right: 10px;
    }

    .profile-name {
      display: flex;
      flex-direction: column;
      /* height: fit-content; */
      line-height: 20px;
      align-items: flex-end;
      justify-content: center;
      // @include break-point(tablet-screen) {
      //   display: none;
      // }
      h3 span {
        max-width: 140px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
      }

      .name {
        font-weight: 600; /* Adjust font weight for name */
        color: #323E59; /* Adjust color for name */
      }

      .email {
        font-weight: 400; /* Adjust font weight for email */
        color: #21709b; /* Adjust color for email */
      }
    }

    h3 {
      color: var(--textColor);
      display: flex;
      align-items: center;
      margin: 0;

      img {
        margin-left: 5px;
        margin-top: 0;
      }
    }

    p {
      font-size: 12px;
      color: #c4c4c4;
      line-height: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 95px;
      overflow: hidden;
    }
  }

  .notify {
    position: relative;

    .badge-pos {
      position: absolute;
      top: -9px;
      border-radius: 50%;
      left: 15px;
      border: 2px solid white;
      background-color: var(--primaryColor) !important;
    }
  }

  .header-top-nav {
    width: auto;
    position: relative;
    margin-right: 40px;
    margin-top: 8px;
    cursor: pointer;
    // @include break-point(tablet-screen) {
    //   display: none;
    // }
    img,
    svg {
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
    }

    a {
      color: var(--textColor);
      // @include fontStyle($ft14, var(--fontRegular));
    }
  }
}

.responsive-navigation{
  .ant-drawer-header{
    display: none;
  }
}
