.sitecreation{
    width: 36%;

  @media only screen and (max-width: 767px) {
     width: 100%;
  }
  @media only screen and (max-width: 1023px) and (min-width: 767px) {
    width: 50%;
  }
}

.Address-selector{
    // width: 230px;
    // height: 40px;
    @media only screen and (max-width: 767px) {
        width: 300px;
     }
     @media only screen and (max-width: 1223px) and (min-width: 767px) {
       width: 130px;
     }

     span{
      font-size: 14px;
      color:rgb(40, 39, 39);
      font-family: InterMedium, sans-serif;
    }
    // .ant-select-selection-item{
    //   flex: none;
    // }
    .ant-select-selection-item{
      text-align: center;
    }
}
