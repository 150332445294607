@keyframes loaderAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loaderAnimation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#loader-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgb(249, 249, 249);
  opacity: 0.6;
.taskClass{
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%) ;
}
}
.loader {
  display: block;
  width: 75px;
  height: 75px;
  margin: 0 auto;
  border-radius: 50%;
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 5px solid transparent;
 // border-top-color:var(--primaryTextColor);
  border-bottom-color: #20709B;
  border-left-color: #20709B;
  border-right-color: #20709B;
  -webkit-animation: loaderAnimation 1s linear infinite;
  animation: loaderAnimation 1s linear infinite;
}
