// Font Face Mixin
@mixin fontFace($name, $location) {
  @font-face {
    font-family: $name;
    src: url($location)
  }
}

// Example -  @include fontFace(LatoThin, './font/lato/lato-thin.ttf');

// Font Style Mixing
@mixin fontStyle ($size, $fontfamily) {
  font-size: $size;
  line-height: 1.3;
  font-family: $fontfamily;
}

// Example -  @include fontStyle(12px, '700');

// Conditional Media Query Mixing
@mixin break-point($point) {
  @if $point == landscape-view-tablet {
    @media screen and (min-width: 1024px) {
      @content;
    }
  } @else if $point == laptop-screen {
    @media screen and (min-width: 992px) {
      @content;
    }
  } @else if $point == mobile-screen {
    @media screen and (min-width: 768px) {
      @content;
    }
  } @else if $point == landscape-mobile-screen {
    @media screen and (min-width: 670px) {
      @content;
    }
  }
  @else if $point == tablet-view {
    @media screen and (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  } @else if $point == tablet-screen {
    @media (max-width: 991px) {
      @content;
    }
  } @else if $point == mobile-view {
    @media screen and (max-width: 767px) {
      @content;
    }
  } @else if $point == xxl-mobile-view {
    @media screen and (max-width: 720px) {
      @content;
    }
  } @else if $point == landscape-mobile-view {
    @media screen and (max-width: 575px) {
      @content;
    }
  } @else if $point == xl-mobile-view {
    @media screen and (max-width: 540px) {
      @content;
    }
  } @else if $point == protrait-mobile-view {
    @media screen and (max-width: 500px) {
      @content;
    }
  }@else if $point == large-mobile-view {
    @media screen and (max-width: 480px) {
      @content;
    }
  }@else if $point == xs-mobile-view {
    @media screen and (max-width: 400px) {
      @content;
    }
  } @else if $point == small-mobile-view {
    @media screen and (max-width: 360px) {
      @content;
    }
  } @else if $point == ie {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      @content;
    }
  }
}

// Prefix Mixing

@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  } 
  #{$property}: $value;
}


// Example -  @include prefix(transform, rotate(45deg), webkit ms);

