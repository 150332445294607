$fontPath: "/assets/fonts/";

@include fontFace(InterBlack, '#{$fontPath}Inter-Black.ttf');
@include fontFace(InterExtraBold, '#{$fontPath}Inter-ExtraBold.ttf');
@include fontFace(InterBold, '#{$fontPath}Inter-Bold.ttf');
@include fontFace(InterSemiBold, '#{$fontPath}Inter-SemiBold.ttf');
@include fontFace(InterMedium, '#{$fontPath}Inter-Medium.ttf');
@include fontFace(InterRegular, '#{$fontPath}Inter-Regular.ttf');
@include fontFace(InterLight, '#{$fontPath}Inter-Light.ttf');
@include fontFace(InterExtraLight, '#{$fontPath}Inter-ExtraLight.ttf');
@include fontFace(InterThin, '#{$fontPath}Inter-Thin.ttf');



