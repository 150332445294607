.router-wrapper{
  display: flex;
  justify-content: space-between;
}

.activation-note{
  color: red;
  padding: 0 25px;
  margin: 0;
}

.router-form-wrapper{
  padding: 25px;
  border-radius: 10px;
  background-color: white;
  //margin: 10px 0;

  h2{
    margin: 0 0 10px 0;
    display: flex;
    justify-content: center;
    font-size: 24px;
  }

  img{
     width: 500px;
  }

  .iccid-note{
    color: red;
    font-size: 14px;
  }

  .router-form{
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    height: 100%;

    .router-form-right-panel{
      .ant-form-item-control-input, input{
        width: 100%;
        margin-right: 20px;
      }
    }

   /* >div:first-child{
      >div{
        width: 320px;
      }
    }*/
  }

  .get-info-btn-box{
    display: flex;
    justify-content: flex-end;

    .ant-form-item{
      margin-bottom: 0;
    }

    button{
      width: 200px;
    }
  }
}

.sim-image-block{
  padding: 25px;
  border-radius: 10px;
  background-color: white;
  width: 41%;
  display: flex;
  justify-content: center;

  img{
    width: 500px;
  }
}

.router-info-box{
  width: 49%;
}

.sim-info-box{
  margin-top: 30px;

  .router-info{

    span{
      min-width: 30%;
    }
  }
}

.router-info-box, .sim-info-box{
  padding: 25px;
  background-color: #FFFFFF;
  border-radius: 10px;

  display: flex;
  flex-direction: column;

  h2{
    margin: 0 0 25px 0;
    font-size: 24px;
    display: flex;
    justify-content: center;
    span{
      margin-left: 5px;
    }
  }

  .router-info{
    margin: 15px 0;
    font-size: 16px;
    display: flex;

    span{
      font-family: InterBold, sans-serif;
      margin-right: 10px;
     // min-width: 33%;
      display: inline-block;
    }
  }

  .confirm-btn-box{
    margin-top: 35px;
    /* width: 62px; */
    display: flex;
    justify-content: flex-end;

    button{
      width: 200px;
      height: 45px;
    }
  }
}

.sim-error{
  //color: red;
  background-color: #ca393926;
  border: 1px solid red;

  h2>span{
    color: red;
  }
}

.sim-success{
  //color: green;
  background-color: #0081011a;
  border: 1px solid #008101;

  h2>span{
    color: green;
  }
}

.sim-display-info{
  display: flex;
  justify-content: space-between;

  .sim-info-left{
    width: 41%;
  }

  .sim-info-right{
    width: 58%;
  }

  .sim-info-status{
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-family: 'InterMedium';
    margin-top: 15px;
  }
}

.router-info-flex{
  display: flex;
  align-items: baseline;

  .select-dropdown-field{
    width: 40%;
  }
}

.entries-heading{
  font-family: InterBold, sans-serif;
  margin: 0 25px 10px 25px;
  font-size: 24px;
}

.entries-info-wrapper{
  padding: 25px;
  border-radius: 10px;
  background-color: white;
  margin: 25px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;

  &>div{
    font-size: 16px;
    font-weight: bold;
    flex-basis: 33.3%;

    span{
      margin-left: 5px;
    }
  }
}

.entries-wrapper{
  padding: 15px;
  border-radius: 10px;
  margin: 0 25px;
  background: black;
  color: white;
  font-family: 'InterRegular';
  font-size: 13px;
  max-height: 480px;
  overflow-y: scroll;

  div{
    margin-bottom: 6px;

    span{
      display: inline-block;
      margin-right: 10px;
      color: darkturquoise;
    }
  }
}

.input-mask{
    border-radius: 5px;
    background-color: white;
    padding: 4px 11px;
    border: 1px #d9d9d9 solid;

}
.input-mask:focus{
    border-color: #1677ff;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    outline: 0;
}
.input-mask:disabled{
    color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.04);
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}

.log-loader {
  width: 18px;
  height: 18px;
  border: 1px solid #fde801;
  border-bottom-color: transparent;
  border-radius: 50%;
  margin: 0px 5px;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 0.4s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  }


@media only screen and (max-width: 767px) {
  .router-wrapper{
    flex-direction: column;

    .router-form-wrapper{
      width: 100% !important;
      margin-bottom: 20px;
    }

    .router-info-box{
      width: 100%;
    }

    .router-info{
      font-size: 14px;
    }
  }
}

.status-color{
  color: orange;
}

.Success{
  color: green;
}
.Failed{
  color: red;
}

.logs-display{
  padding: 15px 25px;
  border-radius: 10px;
  margin: -15px 25px 0px 25px;
  background: white;
  color: black;
  font-size: 16px;
  font-weight: bold;
  flex-basis: 33.3%;
  max-height: 480px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.config-note{
  font-size: 16px;
  font-family: 'InterBold', sans-serif;
  margin-top: 20px;
  padding: 0 25px 0 25px;
}

@media only screen and (max-width: 576px) {
  .config-note{
    font-size: 12px;
    font-family: 'InterRegular', sans-serif;
  }
}
