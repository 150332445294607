.logs-setup-top {
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-top: 5px;

  //justify-content: flex-end;

  .title {
    font-size: 20px;
  }

  form {
    div {
      margin-bottom: 0;
    }
  }

  .ant-breadcrumb{
    margin-bottom: 0;
  }
}

.ant-tabs-nav{
  margin: 1px 0 0 0;
  background: white;
  padding: 0 0 0 25px;
}

.add-new-modal-wrapper {
  padding-top: 5px;

  label {
    font-family: InterSemiBold, sans-serif;
  }

  .width-100 {
    width: 100%;
  }
}

.ant-form-item-required {
  &::before {
    right: -11px;
    position: absolute !important;
  }
}

.file-tile-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 1.2%;
  padding: 25px 25px 0 25px;

  img{
    width: 35px;
    height: 35px;
    margin-right: 20px;
  }

  .router-details{
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .router-date{
      margin-bottom: 8px;
    }

    .succeeded{
      background-color: green;
    }

    .failed{
      background-color: red;
    }

    .success{
      background-color: green;
    }

    .success{
      background-color: green;
    }

    .aborted{
      background-color: red;
    }

    .running{
      background-color: orange;
    }

    .router-status{
      font-size: 10px;
      padding: 3px 8px;
      border-radius: 10px;
      color: white;
      font-family: 'InterBold', sans-serif;
    }
  }
}

.file-empty-data{
  width: 100%;
  justify-content: center;
  height: 245px;
  background-color: #fff;
  display: flex;
  align-items: center;
  font-family: InterSemiBold, sans-serif;
  font-size: 24px;
}

.file-tile{
  padding: 18px;
  margin-bottom: 15px;
  width: 24%;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: InterSemiBold, sans-serif;
  font-size: 13px;
  cursor: pointer;
  box-shadow: inset 0 1px 0 0 #fff, 0 0 0 1px rgba(0, 0, 0, 0.09), 0 1px 0 0 rgba(0, 0, 0, 0.09), 0 2px 2px 0 rgba(0, 0, 0, 0.04), 0 3px 3px 0 rgba(0, 0, 0, 0.02), 0 4px 4px 0 rgba(0, 0, 0, 0.01);

  .file-tile-icon{
    display: flex;
    align-items: center;
  }
}

.bulk-upload-table-container{
    padding: 25px;
}

.entries-box{
  margin-top: 20px;
}

.failed{
  color: red;
}

.succeeded{
  color: green;
}

.success{
  color: green;
}

@media only screen and (max-width: 576px) {
  .user-setup-top{
   // flex-direction: column;

    .search-box{
      width: 90%;
    }

    button{
      width: 100%;
      height: 40px;
      margin-top: 15px;
    }
  }
}
