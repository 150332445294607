.dashboard-left-box {
  display: flex;
  justify-content: space-between;

  .filter-wrapper-box {
    margin-top: 10px;
  }
}

.dashboard-setup-top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;

  .filter-btn-wrapper {
    margin-right: 10px;
  }
}

.dashboard-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.graph-wrapper {
  display: flex;
  flex-direction: column;
  width: 34%;
  //justify-content: space-between;

  .graph-box {
    height: 340px;
    padding: 10px;
  }
}

.tables-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 65%;

  > div {
    margin: 10px;
  }

  .table-top-details{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2px;
    background: #FFFFFF;
    box-shadow: 0px 3px 20px rgba(216, 216, 216, 0.1607843137);
    border-radius: 10px;
    padding: 21px;
  }

  .tbb-details{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 16px;
  }

  .title {
    color: #011838;
    font-family: InterSemiBold, sans-serif;
    font-size: 17px;
    margin: 0;

    span{
      color: #21709b;
      font-family: 'InterBold';
    }
  }

  table {
    min-height: 240px;

    thead {
      th {
        &:before {
          display: none;
        }
      }
    }
  }

  .schedule-table {
    thead {
      th {
        color: #011633;
        font-family: InterSemiBold, sans-serif;
        font-size: 14px;

        &:nth-child(2) {
          color: #FF861F;
        }

        &:nth-child(3) {
          color: #2B95FF;
        }

        &:nth-child(4) {
          color: #07B14F;
        }
      }
    }
  }

  .table-summary{
      tbody{
        tr:first-child{
          td:first-child{
            width: 15%;
          }
          td:nth-child(2){
            width: 35%;
          }
          td:nth-child(3){
            width: 20%;
          }
          td:nth-child(4){
            width: 20%;
          }
        }
      }
  }

  .table-summary-one{
    thead{
      display: none;
    }
  }

  .table-summary-two{
    table{
      min-height: 300px !important;
      thead{
        display: none;
      }
    }
  }

  .table-summary-tbb{
    table{
      min-height: 100px !important;
      tbody{
        tr:first-child{
          td:first-child{
            width: 15%;
          }
          td:nth-child(2){
            width: 35%;
          }
          td:nth-child(3){
            width: 20%;
          }
          td:nth-child(4){
            width: 20%;
          }
        }
      }
    }
  }

  .cable-table {
    table {
      thead {
        th:not(:first-child) {
          padding: 2px;
        }
      }

      tbody {
        tr {
          td:not(:first-child) {
            padding: 2px;
          }
        }
      }
    }
  }
}

.custom_tooltip {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
  z-index: 21;
  align-items: center;
  justify-items: center;
  width: auto;
  height: 28px;
  padding: 0 5px;
  border: solid 1px black;
  background-color: white;
  border-radius: 4px;
}

.temp-dash {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: InterBold, sans-serif;
  font-size: 24px;
}

.tooltip-chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: InterSemiBold, sans-serif;
}

.color-box-tooltip {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 10px;
  //background-color: red;
}

@media only screen and (max-width: 1500px) {
  body {
    .graph-one-box {
      //width: 450px;
    }
  }
}

@media only screen and (max-width: 1281px) {
  .dashboard-left-box {
    flex-direction: column-reverse;

    .filter-wrapper-box {
      margin-bottom: 10px;
      width: 100%;
    }
  }

  .dashboard-wrapper{
    flex-direction: column;
  }

  .graph-wrapper {
    flex-direction: column;
    width: 100%;
  }

  .tables-wrapper {
    flex-direction: column;
    width: 100%;

    > div {
      margin: 0;
      width: 100%;
    }
  }
}
