.login-wrapper{
  display: flex;
  justify-content: center;
  //padding-top: 120px;
  flex-direction: column;

  .logo{
    background: white;
    height: auto;
    margin: 0 0 60px 0;
    justify-content: flex-start;
    padding-left: 10px;
  }

  .login-body{
    display: flex;
    justify-content: center;
  }

  label{
    font-size: 16px;
    font-family: InterMedium, sans-serif;
  }

  button{
    margin-top: 20px;
  }

  input{
    height: 30px;
  }
}

.change-password-wrapper{
  margin-top: 45px;
}

.logo-img{
   width: 170px;
  height: 60px;
}


.forget-password-card{
  width: 36%;

  @media only screen and (max-width: 767px) {
     width: 100%;
  }
}

.forgot-box{
  display: flex;
  justify-content: flex-end;
  margin: 0;

  .forgot-link{
    cursor: pointer;
    color: #21709b;
    font-family: InterSemiBold, sans-serif;
    font-size: 14px;
  }
}

.login-card{
  width: 36%;

  .ant-card-head-title{
    font-size: 22px;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    margin: 0 35px;
  }

  @media only screen and (max-width: 1023px) and (min-width: 767px) {
    width: 50%;
  }
}
