.bulk-upload-setup-top {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  justify-content: space-between;

  .title {
    font-size: 20px;
  }

  form {
    div {
      margin-bottom: 0;
    }
  }

  .search-container {
    flex-grow: 1;
  }

  .button-container {
    display: flex;
    align-items: center;
  }
}

.add-new-modal-wrapper {
  padding-top: 5px;

  label {
    font-family: InterSemiBold, sans-serif;
  }

  .width-100 {
    width: 100%;
  }
}

.ant-form-item-required {
  &::before {
    right: -11px;
    position: absolute !important;
  }
}

.bulk-tile-wrapper{
  display: flex;
  flex-wrap: wrap;
  column-gap: 2%;
  margin-top: 25px;

  img{
    width: 50px;
    height: 50px;
    margin-right: 8px;
  }
}

.bulk-site-tile{
  padding: 18px;
  margin-bottom: 15px;
  width: 32%;
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: InterSemiBold, sans-serif;
  font-size: 13px;
  cursor: pointer;
  box-shadow: inset 0 1px 0 0 #fff, 0 0 0 1px rgba(0, 0, 0, 0.09), 0 1px 0 0 rgba(0, 0, 0, 0.09), 0 2px 2px 0 rgba(0, 0, 0, 0.04), 0 3px 3px 0 rgba(0, 0, 0, 0.02), 0 4px 4px 0 rgba(0, 0, 0, 0.01);

  .file-tile-icon{
    display: flex;
    align-items: center;
    width: 75%;
  }
}

.bulk-upload-table-container{
    padding: 25px;
}

.bulk-upload-table-wrapper tr:not(:first-child) td, .bulk-upload-table-wrapper tr th{
  padding: 5px !important;
}

.bulk-upload-table-wrapper{
  table{
    td:not(:first-child){
      min-width: 50px;
      max-width: 50px;
    }

    tbody{
      tr{
        td:nth-child(1){
          width: 1%;
        }
        th:nth-child(1){
          width: 1%;
        }
        td:nth-child(13){
          border-right: 1px solid #DEE0E5;
        }
        td:nth-child(4),  td:nth-child(8){
          border-right: none;
        }
      }
    }
  }

  .disable-icon{
    opacity: 0.4;
    cursor: unset;
  }
}

.mandatory-title{
  display: flex;
  span{
    color: red;
    margin-left: 4px;
    margin-top: -3px;
    display: inline-block;
  }
}

.empty-table-block {
  width: 100%;
  background: white;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  color: #21709b;

  .ant-empty-description{
    display: flex;
    justify-content: center;
  }
}

.site-input-block{
  margin-bottom: 30px !important;
}

.upid-link{
  color: #20709B;
  cursor: pointer;
}

.disable-it{
  opacity: 0.5;
}

@media only screen and (max-width: 576px) {
  .user-setup-top{
   // flex-direction: column;

    .search-box{
      width: 90%;
    }

    button{
      width: 100%;
      height: 40px;
      margin-top: 15px;
    }
  }
}
