.inspection-modal-wrapper{
  .ant-modal-title{
    font-size: 20px;
    font-family: InterBold, sans-serif;
    color: #323E59;
  }

  tr:nth-child(even){background-color: #f2f2f2}

  .business-box{
    color: black;
    margin-bottom: 5px;
    font-size: 15px;

    span{
      font-family: InterSemiBold, sans-serif;
      margin-right: 8px;
    }
  }

  .last-business-box{
    margin-bottom: 15px;
  }

  .inspection-modal{
    a{
      color: #002AFF;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: InterSemiBold, sans-serif;
    }

    img{
      cursor: pointer;
      margin-right: 10px;
      width: 15px;
    }

    .detail-box{
      display: flex;
      justify-content: space-between;
    }
  }
}
