.user-setup-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid grey;
  padding-bottom: 10px;
  margin-bottom: 20px;

  .title {
    font-size: 20px;
  }

  form {
    div {
      margin-bottom: 0;
    }
  }
}

.add-new-modal-wrapper {
  padding-top: 5px;

  label {
    font-family: InterSemiBold, sans-serif;
  }

  .width-100 {
    width: 100%;
  }
}

.search-box {
  color: #717C8B;
  border-radius: 6px;
  width: 328px;
  height: 40px;
  padding: 10px 14px;
  font-size: 14px;
  font-family: InterMedium, sans-serif;
  border: none;

  &:focus {
    box-shadow: none;
    border: none;
  }

  &:hover {
    box-shadow: none;
    border: none;
  }
}

.ant-form-item-required {
  &::before {
    right: -11px;
    position: absolute !important;
  }
}

.ant-select-selection-search{
  margin-inline-start: 0px !important;
}

.multi-dropdown{
  input, .ant-select-selector, .ant-picker-range {
    min-height: 40px !important;
  }

  .ant-select-selector{
    padding: 5px 8px !important;
  }
}

@media only screen and (max-width: 576px) {
  .user-setup-top{
   // flex-direction: column;

    .search-box{
      width: 90%;
    }

    button{
      width: 100%;
      height: 40px;
      margin-top: 15px;
    }
  }
}
