.target-tables-wrapper{
  display: flex;
  justify-content: space-between;

  >div{
    border-radius: 15px;
   // margin: 10px;
    width: 49%;
  }

  .paginated-table-wrapper{
    border-radius: 0 0 10px 10px;
  }

  .table-title{
    color: #011838;
    font-size: 15px;
    font-family: InterSemiBold, sans-serif;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #F7F7F7;
    background: white;
    border-radius: 10px 10px 0 0;
    box-shadow: 0px 3px 20px #0000000A;

    @media only screen and (max-width: 576px) {
      font-size: 12px;
      button{
        font-size: 12px;
        margin-left: 10px;
        padding: 4px 8px;
      }
    }
  }

  .month-table-wrap{
    width: 38%;
  }

  .week-table-wrap{
    width: 60%;
  }

  @media only screen and (max-width : 1281px) {
      flex-direction: column;

    .month-table-wrap, .week-table-wrap{
      width: 100%;
      margin: 0 0 40px 0;
    }
  }
}

