.status-block{
  display: flex;
  align-items: center;
}

.status-indicator{
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50px;
 // background: #1D1D1F;
  margin-right: 10px;
}

.reason-block{
  padding: 0 0 0 15px;
}

.text-ellipsis{
  overflow: hidden;
  //white-space: nowrap;
  text-overflow: ellipsis;
  display: inline;
}

.reason-block-without-dot, .reason-block-without-dot li {
  list-style-type: none; /* This removes the automatic bullet points */
}
