.paginated-table-wrapper {
  box-shadow: 0px 3px 20px #0000000A;
  border-radius: 10px;
  background: white;

  tr:nth-child(2n) td {
    background-color: #F7F7F7;
  }

  .ant-pagination-options{
    //display: none;
    margin: 0 20px;
  }

  .row-class {
    color: #20709B;
    font-size: 14px;
    font-family: InterSemiBold, sans-serif;

    a{
      color: #20709B;
    }
  }

  .red-row {
    //background-image: linear-gradient(to right, #cce9ee, #37A3EB);

    /* Initial state with a subtle 3D effect */
    box-shadow:
      inset 0 8px 8px -8px rgba(255, 255, 255, 0.6), /* top white glow */
      inset 0 -8px 8px -8px rgba(0, 0, 0, 0.1); /* bottom shadow */

    &:hover {
      background-image: linear-gradient(to right, #cce9ee, #37A3EB);

      /* Enhanced 3D effect on hover */
      box-shadow:
        inset 0 8px 8px -4px rgba(255, 255, 255, 0.6), /* top white glow */
        inset 0 -8px 8px -4px rgba(0, 0, 0, 0.2); /* bottom shadow */
    }

    td {
      background-color: #cce9ee !important;
      border-color: transparent !important;
      border-bottom: 1px solid lightskyblue !important;
    }

    td:hover {
      background-color: #cce9ee !important;
      border-color: transparent !important;
      border-bottom: 1px solid lightskyblue !important;
    }
  }

  .inspection-clicked {
    td {
      background-color: #d7d7d7 !important;
    }

    td:hover{
      background-color: #d7d7d7 !important;
    }
  }

  .ant-table-thead .ant-table-cell {
    background-color: #FFFFFF;
    color: #989EAC;
    font-size: 12px;
    font-family: InterMedium, sans-serif;
    text-transform: uppercase;

    .ant-table-column-sorters{
      justify-content: flex-start;
      .ant-table-column-title{
        flex: 0;
      }
    }
  }

  .ant-table-thead{
    th.ant-table-column-sort{
      background-color: #FFFFFF;
    }
  }

  tr:not(:first-child) td, tr th{
    padding: 10px 20px !important;
   // overflow: hidden;
  }

  tr td:nth-child(n+2){
    font-family: InterMedium, sans-serif;
    color: #323E59;
  }

  .na-text{
    color: #A7A7A7;
  }

  td.ant-table-column-sort{
    background: #ffffff;
  }

  @media only screen and (max-width: 576px) {
    .row-class {
      font-size: 12px;
    }

    .hide-table{
      display: none;
    }
  }
}

@media only screen and (max-width: 576px) {
  .hide-table{
    display: none;
  }
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.editable-row .editable-cell-value-wrap {
  padding-left: 11px;
  min-height: 25px;
}

.gradient-row {
  background: linear-gradient(to right, #ff7e5f, #feb47b);
  color: #fff; /* Adjust text color to ensure readability */
}




