.chart-box{
  background: #FFFFFF;
  box-shadow: 0px 3px 20px #D8D8D829;
  border-radius: 10px;
  margin: 10px;
  //width: 32%;
  height: 400px;

  .chart-box-header{
    border-bottom: 2px solid #F7F7F7;
    padding: 15px;
    color: #011633;
    display: flex;
    justify-content: space-between;

    .title{
      font-family: InterSemiBold, sans-serif;
      font-size: 15px;
    }

    button{
      height: 15px;
      padding: 8px 14px;
      font-size: 11px;
      display: flex;
      align-items: center;
      font-family: InterSemiBold, sans-serif;
    }
  }
}

.low-opacity{
  opacity: 0.6;
}

@media only screen and (max-width : 1281px) {
  .chart-box{
    width: 100%;
    margin: 0 0 20px 0;
  }
}
