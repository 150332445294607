.site-schedule-wrapper{
//  padding: 25px;
  tr td:nth-child(4), tr td:nth-child(6), tr td:nth-child(8) {
    border-right: 1px solid #DEE0E5;
  }
  tr:not(:first-child) td, tr th{
    padding: 16px 24px !important;
  }

  tr td:nth-child(n+4){
    font-family: InterMedium, sans-serif;
    color: #323E59;
  }

  .na-text{
    color: #A7A7A7;
  }

  .table-img{
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .edit-btn{
    margin-left: 8px;
  }
}

.inspection-modal-wrapper{
  .ant-modal-title{
    font-size: 20px;
    font-family: InterBold, sans-serif;
    color: #323E59;
  }

  .inspection-modal{
    a{
      color: #002AFF;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: InterSemiBold, sans-serif;
    }

    img{
      cursor: pointer;
      margin-right: 10px;
      width: 15px;
    }
  }
}

.create-schedule-btn{
  background-color: #20709B;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  font-family: 'InterSemiBold', sans-serif;
  padding: 5px 10px;
  cursor: pointer;
}

.schedule-datepicker{
  .ant-picker{
    height: 40px;
    width: 100%;
  }
}

button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: auto;
}

.tabs-container{
  .ant-tabs-nav{
    margin: 1px 0 0 0;
    background: white;
    padding: 0 0 0 25px;
  }

  .ant-tabs-content-holder{
    padding: 25px;
  }
}

.filter-btn-wrapper{
  position: relative;
  margin-left: 10px;

  .filter-btn{
    background-color: white;
    color: #717C8B;
    border-radius: 6px;
    height: 40px;
    padding: 10px 14px;
    font-size: 14px;
    font-family: InterMedium, sans-serif;
    cursor: pointer;
  }

  .filter-checkboxes{
    position: absolute;
    width: 160px;
    top: 42px;
    right: 0;
    z-index: 4;
    color: #717C8B;
    padding: 10px 20px;
    font-size: 14px;
    font-family: InterMedium, sans-serif;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 20px #0000000A;
    border-radius: 10px;
    background: white;

    >div{
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      label{
        margin: 0;
      }
    }

    button{
      color: white;
      background: #21709b;
      padding: 4px 0;
      margin-top: 10px;
      font-size: 14px;
      font-family: InterMedium, sans-serif;
      cursor: pointer;
    }

    .ant-checkbox-group{
      flex-direction: column;

      label{
        margin-inline-start:0
      }
    }

    .filter-heading{
      margin-bottom: 6px;
      border-bottom: 1px solid lightgrey;
      font-family: 'InterBold', sans-serif;
      padding-bottom: 4px;
    }
  }
}

.table-filter-wrapper {
  display: flex;
  overflow-y: scroll;
  justify-content: space-between;
}

.filter-wrapper-box{
  width: 20%;
  box-shadow: 0px 3px 20px #0000000A;
  border-radius: 10px;
  background: white;
  height: fit-content;

  .filter-checkboxes{
    color: #717C8B;
    padding: 10px 20px;
    font-size: 14px;
    font-family: InterMedium, sans-serif;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 20px #0000000A;
    border-radius: 10px;
    background: white;


    >div{
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      label{
        margin: 0;
        font-family: InterMedium,sans-serif;
      }
    }

    button{
      color: white;
      background: #21709b;
      padding: 4px 0;
      margin-top: 10px;
      font-size: 14px;
      font-family: InterMedium, sans-serif;
      cursor: pointer;
      border-radius: 8px;
      height: 40px;
    }

    .ant-checkbox-group{
      flex-direction: column;

      label{
        margin-inline-start:0
      }
    }

    .filter-heading{
      margin-bottom: 6px;
      border-bottom: 1px solid lightgrey;
      font-family: 'InterBold', sans-serif;
      padding-bottom: 6px;
    }
  }
}

.turn-red{
  .schedule-date{
    color: red;
    font-family: 'InterSemiBold', sans-serif;
  }
}

.site-logs{
  padding: 0;
  list-style-type: none
}

.site-history-table{
  tr td:nth-child(3) {
    border-right: 1px solid #DEE0E5;
  }

  tr td:nth-child(4), tr td:nth-child(6), tr td:nth-child(8) {
    border-right: none;
  }
}

.dual-line{
  position: relative;
  &:not(:last-child){
    margin-bottom: 15px;
  }
}

.dual-line:first-child{
  min-height: 30px;
}

.close-filter-btn{
  display: flex;
  color: #21709b;
  justify-content: flex-end;
  margin: 10px 25px 0;
  cursor: pointer;
  font-family: 'InterBold', sans-serif;
}

.strikeText{
  text-decoration: line-through;
}

.disableRow{
   opacity: 0.5;
}

.min-height{
  min-height: 32px;
}

.img-strike{
  &:before{
    content: "____";
    position: absolute;
    top: -7px;
    left: 22px;
  }
}

.revert-status-field{
  .ant-form-item-control-input{
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .filter-wrapper-box{
    width: 100%;
    height: auto;
  }
}
