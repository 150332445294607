.kite-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 8%;

  .kite-headers{
    font-family: 'InterBold', sans-serif;
    font-size: 20px;
    margin-bottom: 15px;
    margin-top: 10px;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;

    &:not(:first-child){
      margin-top: 30px;
    }
  }

  .kite-box {
    min-height: 100px;
    padding: 30px;
    margin-bottom: 15px;
    width: 94%;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: InterSemiBold, sans-serif;
    font-size: 16px;
    cursor: pointer;
    box-shadow: inset 0 1px 0 0 #fff, 0 0 0 1px rgba(0, 0, 0, .09), 0 1px 0 0 rgba(0, 0, 0, .09), 0 2px 2px 0 rgba(0, 0, 0, .04), 0 3px 3px 0 rgba(0, 0, 0, .02), 0 4px 4px 0 rgba(0, 0, 0, .01);

    span {
      color: #4f4f4f;
      font-size: 12px;
      display: inline-block;
      margin-top: 4px;
    }
  }

  .upload-box{
    display: flex;
    width: 94%;
    justify-content: space-between;

    >div:first-child{
      width: 88%;
    }

    >div:last-child{
      width: 11%;
    }
  }

  .ant-collapse{
    background-color: transparent;

    .ant-collapse-content-box{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 25px;
    }

    .ant-collapse-header{
      display: flex;
      align-items: center !important;
      padding: 30px !important;
    }

    .ant-collapse-header-text{
      font-family: 'InterBold', sans-serif;
      font-size: 20px;
    }
  }

  .hide-box{
    display: none;
  }
}

.email-modal-wrapper{
  .ant-form-item-explain-error{
    padding-top: 10px;
  }

  .error-display{
    color: #ff4d4f;
    margin-top: -22px;
  }
}
