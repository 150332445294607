.cable-status-box{
  background: #FFFFFF;
  border-radius: 4px;
  padding: 10px 12px;
  font-family: InterMedium, sans-serif;
  font-size: 12px;
  width: 130px;
  height: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.sow-approved{
  border: 1px solid #5EC472;
  color: #5EC472;
}

.sow-submitted{
  border: 1px solid #20709B;
  color: #20709B;
}

.sow-required{
  border: 1px solid #ED1E1E;
  color: #ED1E1E;
}


.sow-approved-active{
  border: 1px solid #5EC472;
  color: white;
  background-color: #5EC472;
}

.sow-submitted-active{
  border: 1px solid #20709B;
  background-color: #20709B;
  color: white;
}

.sow-required-active{
  border: 1px solid #ED1E1E;
  background-color: #ED1E1E;
  color: white;
}

.cable-btn{
  border: 1px solid #20709B;
  color: #20709B;
  font-family: InterMedium, sans-serif;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  padding: 6px 12px;
}

.cable-table-wrapper{
  tr td:nth-child(3) {
    border-right: 1px solid #DEE0E5;
  }
}

.site-fault-table-wrapper{
  tr td:nth-child(4) {
    border-right: 1px solid #DEE0E5;
  }

  tr td:nth-child(3) {
    border-right: none;
  }
}
