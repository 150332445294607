@import "assets/stylesheet/mixin";
@import "assets/stylesheet/font";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f3f5f9;
}

body, button, input, textarea {
  font-family: InterRegular, sans-serif;
  font-size: 16px;
  box-sizing: border-box;
}

button {
  outline: none;
  background: none;
  border: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.heading-title {
  font-size: 16px;
  color: #9e9e9e;
  line-height: 24px;
  font-weight: 600;
  font-style: italic;
  letter-spacing: 0;
  margin: 0 0 8px 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

label{
  font-family: InterSemiBold, sans-serif;
  font-size: 14px;
}

.secondary-tab-btn{
  height: 45px;
  color: #21709b;
  border-color: #21709b;
  font-family: 'InterMedium', sans-serif;

  &:hover{
    color: #FFFFFF !important;
    background-color: #21709b !important;
    border-color: #21709b !important;
  }
}

.primary-tab-btn{
  height: 45px;
  color: #FFFFFF;
  background-color: #21709b;
  border-color: #21709b;
  font-family: 'InterMedium', sans-serif;

  &:hover{
    color: #21709b !important;
    border-color: #21709b !important;
    background-color: #FFFFFF !important;
  }

  &:disabled:hover,
  &[disabled]:hover{
    cursor: not-allowed;
    border-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
  }

  &:focus-visible{
    outline: none !important;
  }
}

.confirm-modal-wrap{
  button{
    @extend .primary-tab-btn;
    height: 30px;
    padding: 0 30px;
  }
}

.height-40{
  input, .ant-select-selector, .ant-picker-range {
    height: 40px !important;
  }

  .ant-select-selector{
    padding: 4px 11px !important;
  }

  .ant-picker, .ant-input-password{
    padding: 0 11px;
  }
}

.pd-25{
  padding: 25px;
}

.margin-top-space{
  margin-top: 35px;
}

.err-margin{
  margin-bottom: 8px;
}

.ant-form-item-required::after{
  display: none !important;
}

.pagination-wrapper{
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;
  margin-top: 25px;
}
