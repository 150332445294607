.ant-breadcrumb{
  margin-bottom: 15px;

  .app-breadcrumb-link{
    color: #074179;

    &:hover{
      background-color: transparent;
    }
  }
}
